import * as React from "react"
import { FiCalendar } from "react-icons/fi"
import { Badge } from "../components/Badge"
import { ButtonLink } from "../components/Button"
import { Carousel } from "../components/Carousel"
import { Container } from "../components/Container"
import { Link } from "../components/Router"
import { Meta } from "../components/Meta"
import { Modal } from "../components/Modal"
import { Skeleton } from "../components/Skeleton"
import { EmptyEvent } from "../features/Event"
import { NewsletterSection } from "../features/Newsletter"
import { useAutoPosition, usePage } from "../hooks"
import { dateFormat } from "../typeconv"
import { isSameDay } from "../typecmp"
import { Blog as BlogService } from "../services/api/Blog"

const blogService = new BlogService({
  host: process.env.REACT_APP_API_HOST
})
const articleDefaultThumbnail = "/image/placeholder-1280x720.png"
const eventDefaultThumbnail = "/image/placeholder-1080x1080.png"

type ArticleItem = {
  slug: string
  title: string
  mode: string
  url?: string
  content?: string
  thumbnailUrl?: string
  createdAt: Date
  categories: {
    name: string
  }[]
}

type EventItem = {
  title: string
  content?: string
  thumbnailUrl?: string
  venue?: string
  fees?: string
  registrationLink?: string
  startedAt?: Date
  finishedAt?: Date
  createdAt: Date
  categories: {
    name: string
  }[]
}

type ElementEventItem<T> = T & {
  hover: boolean
  showYear: boolean
}

type EventState = {
  loading: boolean
  items: ElementEventItem<EventItem>[]
  totalItems: number
  opened: boolean
  detail: EventItem
  yearIndexes: Set<string>
}

export default function News(): React.JSX.Element {
  const [page] = usePage({
    slug: "news-events"
  })
  const [articles, setArticles] = React.useState({
    loading: false,
    items: [] as ArticleItem[],
    totalItems: 0,
    filter: {
      page: 1,
      totalItems: 2
    }
  })
  const [upcomingEvents, setUpcomingEvents] = React.useState({
    loading: false,
    items: [] as ElementEventItem<EventItem>[],
    totalItems: 0,
    opened: false,
    detail: {} as EventItem,
    filter: {
      page: 1,
      totalItems: 4,
      mode: "upcoming",
    },
    yearIndexes: new Set<string>(),
  } as EventState)
  const [pastEvents, setPastEvents] = React.useState({
    loading: false,
    items: [] as ElementEventItem<EventItem>[],
    totalItems: 0,
    opened: false,
    detail: {} as EventItem,
    filter: {
      page: 1,
      totalItems: 4,
      mode: "past",
    },
    yearIndexes: new Set<string>(),
  } as EventState)

  async function searchArticles(filter: Record<string, any>) {
    setArticles((prevState) => {
      return {
        ...prevState,
        loading: true,
      }
    })
    const searchArticles = await blogService.SearchArticle(filter)
    setArticles((prevState) => {
      return {
        ...prevState,
        loading: false,
      }
    })
    if (searchArticles.error) {
      return
    }

    setArticles((prevState) => {
      return {
        ...prevState,
        items: searchArticles.data.items.map((item) => {
          return {
            ...item,
            hover: false,
          }
        }),
        totalItems: searchArticles.data.summary.total_items
      }
    })
  }

  async function searchEvents(setter: React.Dispatch<React.SetStateAction<EventState>>, filter: Record<string, any>) {
    setter((prevState) => {
      return {
        ...prevState,
        loading: true,
      }
    })
    const searchEvents = await blogService.SearchEvent(filter)
    setter((prevState) => {
      return {
        ...prevState,
        loading: false,
      }
    })
    if (searchEvents.error) {
      return
    }

    setter((prevState) => {
      const yearIndexes = new Set(prevState.yearIndexes.values())

      const items = searchEvents.data.items.map((item) => {
        const year = dateFormat(item.startedAt, 'YYYY')
        const showYear = !yearIndexes.has(year)
        if (!yearIndexes.has(year)) {
          yearIndexes.add(year)
        }

        return {
          ...item,
          hover: false,
          showYear
        }
      })

      return {
        ...prevState,
        items,
        yearIndexes,
        totalItems: searchEvents.data.summary.total_items
      }
    })
  }

  function selectEvent(setter: React.Dispatch<React.SetStateAction<EventState>>, event: EventItem) {
    setter((prevState) => {
      return {
        ...prevState,
        opened: true,
        detail: {
          ...event,
        }
      }
    })
  }

  React.useEffect(() => {
    Promise.all([
      searchArticles({
        page: 1,
        totalItems: 100,
      }),
      searchEvents(setUpcomingEvents, {
        page: 1,
        totalItems: 100,
        mode: "upcoming",
      }),
      searchEvents(setPastEvents, {
        page: 1,
        totalItems: 100,
        mode: "past",
      }),
    ])
  }, [])

  const loadingEvents = [1, 2, 3, 4].map((_) => {
    return {
      children: <>
        <CardEvent loading={true} />
      </>
    }
  })
  const loadingArticles = [1].map((_) => {
    return {
      children: <>
        <CardArticle loading={articles.loading} />
      </>
    }
  })

  useAutoPosition()

  return (
    <>
      <Meta>
        {{
          title: `${page.detail.title || "News & Events"}`,
          description: `${page.detail.title || "News & Events"}`,
        }}
      </Meta>

      <div className="w-full h-[250px] md:h-[400px] bg-sta-primary">
        <div className="w-full h-full flex justify-start items-center">
          <Container size="md">
            <p className="font-bold text-2xl md:text-5xl text-white my-10 xl:my-0">
              {page.detail.title || "News & Events"}
            </p>
          </Container>
        </div>
      </div>

      <div className="bg-white py-10 md:py-20">
        <Container size="md">
          <div className="flex flex-col gap-10 md:gap-20">
            {
              page.detail.fields.boolVal("NEWS_VISIBILITY") &&
              <>
                {
                  (articles.loading || articles.items.length > 0) &&
                  <div className="flex flex-col gap-4 md:gap-8">
                    <div className="flex flex-col gap-4">
                      <p className="w-fit font-bold text-black text-xl md:text-4xl border-b-4 border-sta-primary">
                        {page.detail.fields.textVal("NEWS_HEADING_TEXT", `News`)}
                      </p>
                      {
                        !page.detail.fields.empty("NEWS_SUBHEADING_TEXT") &&
                        <p className="text-black text-base md:text-xl">
                          {page.detail.fields.textVal("NEWS_SUBHEADING_TEXT")}
                        </p>
                      }
                    </div>

                    <Carousel
                      className="grid grid-flow-col auto-rows-fr justify-start items-center"
                      variant="secondary"
                      navPosition="tr"
                      slidesPerView={1}
                      spaceBetween={20}
                      autoplay
                      autoplayDelay={3500}
                      grabable navigable>
                      {{
                        items: articles.loading ?
                          loadingArticles :
                          articles.items.map((item) => {
                            return {
                              children: <>
                                <Link to={(item.mode === "url" ? item.url : `/article/${item.slug}`)}
                                  target={(item.mode === "url" ? "_blank" : "_self")}
                                  hideCrawl={item.mode === "url"}>
                                  <CardArticle
                                    item={item}
                                    loading={articles.loading} />
                                </Link>
                              </>
                            }
                          })
                      }}
                    </Carousel>
                  </div>
                }
              </>
            }

            {
              page.detail.fields.boolVal("UPCOMING_EVENT_VISIBILITY") &&
              <div className="flex flex-col gap-4 md:gap-8">
                <div className="flex flex-col gap-4">
                  <p className="w-fit font-bold text-black text-xl md:text-4xl border-b-4 border-sta-primary">
                    {page.detail.fields.textVal("UPCOMING_EVENT_HEADING_TEXT", `Upcoming Events`)}
                  </p>
                  {
                    !page.detail.fields.empty("UPCOMING_EVENT_SUBHEADING_TEXT") &&
                    <p className="text-black text-base md:text-xl">
                      {page.detail.fields.textVal("UPCOMING_EVENT_SUBHEADING_TEXT")}
                    </p>
                  }
                </div>

                {
                  !upcomingEvents.loading && upcomingEvents.items.length === 0 ?
                    <EmptyEvent />
                    :
                    <Carousel
                      className="grid grid-flow-col auto-rows-fr justify-start items-center"
                      variant="info"
                      navPosition="tr"
                      breakpoints={{
                        0: {
                          slidesPerView: 1,
                          spaceBetween: 20,
                        },
                        380: {
                          slidesPerView: 2,
                          spaceBetween: 20,
                        },
                        768: {
                          slidesPerView: 2,
                          spaceBetween: 20,
                        },
                        1280: {
                          slidesPerView: 4,
                          spaceBetween: 35,
                        },
                      }}
                      grabable navigable>
                      {{
                        items: (
                          upcomingEvents.loading ? loadingEvents
                            :
                            (
                              upcomingEvents.items.map((item, i: number) => {
                                return {
                                  children: <>
                                    <CardEvent
                                      onClick={() => { selectEvent(setUpcomingEvents, item) }}
                                      onThumbnailEnter={() => {
                                        setUpcomingEvents((prevState) => {
                                          return {
                                            ...prevState,
                                            items: prevState.items.map((item, j: number) => {
                                              return {
                                                ...item,
                                                hover: i === j ? true : item.hover,
                                              }
                                            })
                                          }
                                        })
                                      }}
                                      onThumbnailLeave={() => {
                                        setUpcomingEvents((prevState) => {
                                          return {
                                            ...prevState,
                                            items: prevState.items.map((item, j: number) => {
                                              return {
                                                ...item,
                                                hover: i === j ? false : item.hover,
                                              }
                                            })
                                          }
                                        })
                                      }}
                                      item={item}
                                      active={item.hover}
                                      loading={upcomingEvents.loading} />
                                  </>
                                }
                              })
                            )
                        )
                      }}
                    </Carousel>
                }
              </div>
            }

            {
              page.detail.fields.boolVal("PAST_EVENT_VISIBILITY") &&
              <>
                {
                  !pastEvents.loading && pastEvents.items.length > 0 &&
                  <div className="flex flex-col gap-4 md:gap-8">
                    <div className="flex flex-col gap-4">
                      <p className="w-fit font-bold text-black text-xl md:text-4xl border-b-4 border-sta-primary">
                        {page.detail.fields.textVal("PAST_EVENT_HEADING_TEXT", `Past Events`)}
                      </p>
                      {
                        !page.detail.fields.empty("PAST_EVENT_SUBHEADING_TEXT") &&
                        <p className="text-black text-base md:text-xl">
                          {page.detail.fields.textVal("PAST_EVENT_SUBHEADING_TEXT")}
                        </p>
                      }
                    </div>

                    <Carousel
                      className="grid grid-flow-col auto-rows-fr justify-start items-center"
                      variant="info"
                      navPosition="tr"
                      breakpoints={{
                        0: {
                          slidesPerView: 1,
                          spaceBetween: 20,
                        },
                        380: {
                          slidesPerView: 2,
                          spaceBetween: 20,
                        },
                        768: {
                          slidesPerView: 2,
                          spaceBetween: 20,
                        },
                        1280: {
                          slidesPerView: 4,
                          spaceBetween: 35,
                        },
                      }}
                      grabable navigable>
                      {{
                        items: (
                          pastEvents.loading ? loadingEvents
                            :
                            (
                              pastEvents.items.map((item, i: number) => {
                                return {
                                  children: <>
                                    <CardEvent
                                      onClick={() => { selectEvent(setPastEvents, item) }}
                                      onThumbnailEnter={() => {
                                        setPastEvents((prevState) => {
                                          return {
                                            ...prevState,
                                            items: prevState.items.map((item, j: number) => {
                                              return {
                                                ...item,
                                                hover: i === j ? true : item.hover,
                                              }
                                            })
                                          }
                                        })
                                      }}
                                      onThumbnailLeave={() => {
                                        setPastEvents((prevState) => {
                                          return {
                                            ...prevState,
                                            items: prevState.items.map((item, j: number) => {
                                              return {
                                                ...item,
                                                hover: i === j ? false : item.hover,
                                              }
                                            })
                                          }
                                        })
                                      }}
                                      item={item}
                                      active={item.hover}
                                      enableYear
                                      showYear={item.showYear}
                                      loading={pastEvents.loading} />
                                  </>
                                }
                              })
                            )
                        )
                      }}
                    </Carousel>

                  </div>
                }
              </>
            }
          </div>
        </Container>
      </div>

      <NewsletterSection />

      <Modal portalId="overlay-root"
        size="2xl"
        opened={upcomingEvents.opened}
        onClose={() => {
          setUpcomingEvents((prevState) => {
            return {
              ...prevState,
              opened: false,
            }
          })
        }}
        lostable
        closeable>
        {{
          header:
            <>
              {
                upcomingEvents.detail.categories && upcomingEvents.detail.categories.length > 0 &&
                <div className="flex flex-row flex-wrap gap-2">
                  {
                    upcomingEvents.detail.categories.map((category, i: number) => {
                      return (
                        <React.Fragment key={`category-item-${i}`}>
                          <Badge variant="secondary" size="sm">
                            <span className="uppercase">
                              {category.name}
                            </span>
                          </Badge>
                        </React.Fragment>
                      )
                    })
                  }
                </div>
              }
            </>,
          content:
            <>
              <ModalEventDetail item={upcomingEvents.detail} />
            </>
        }}
      </Modal>

      <Modal portalId="overlay-root"
        size="2xl"
        opened={pastEvents.opened}
        onClose={() => {
          setPastEvents((prevState) => {
            return {
              ...prevState,
              opened: false,
            }
          })
        }}
        lostable
        closeable>
        {{
          header:
            <>
              {
                pastEvents.detail.categories && pastEvents.detail.categories.length > 0 &&
                <div className="flex flex-row flex-wrap gap-2">
                  {
                    pastEvents.detail.categories.map((category, i: number) => {
                      return (
                        <React.Fragment key={`category-item-${i}`}>
                          <Badge variant="secondary" size="sm">
                            <span className="uppercase">
                              {category.name}
                            </span>
                          </Badge>
                        </React.Fragment>
                      )
                    })
                  }
                </div>
              }
            </>,
          content:
            <>
              <ModalEventDetail item={pastEvents.detail} />
            </>
        }}
      </Modal>
    </>
  )
}

type CardArticleProps = {
  children?: React.ReactNode
  loading?: boolean
  item?: ArticleItem
  onClick?: () => void
}

function CardArticle(props: CardArticleProps): React.JSX.Element {
  const {
    loading = false,
    item,
    onClick = () => { },
  } = props

  const cursor = (loading ? "cursor-wait" : "cursor-pointer")

  return (
    <div className={"relative w-full h-full " + cursor} onClick={onClick}>
      <div className="flex flex-col sm:flex-row w-full h-full">
        <div className="basis-full sm:basis-5/12">
          {
            loading ?
              <Skeleton width="100%" height="100%" appendClassNames="aspect-video rounded-t-3xl sm:rounded-tr-none sm:rounded-l-3xl shadow-lg z-[2]" />
              :
              <img alt="thumbnail" src={item.thumbnailUrl || articleDefaultThumbnail}
                className="w-full h-full object-fit object-center object-no-repeat aspect-video rounded-t-3xl sm:rounded-tr-none sm:rounded-l-3xl shadow-lg z-[2]" />
          }
        </div>
        <div className="basis-full sm:basis-7/12 bg-sta-navy rounded-b-3xl sm:rounded-bl-none sm:rounded-r-3xl shadow-lg">
          <div className="flex flex-col gap-4 p-4 sm:p-7 justify-center items-start">
            {
              loading ?
                <>
                  <Skeleton width="50%" height="10px" rounded />
                  <Skeleton width="100%" height="20px" rounded />
                  <div className="w-full flex flex-col gap-2">
                    <Skeleton width="90%" height="10px" rounded />
                    <Skeleton width="90%" height="10px" rounded />
                    <Skeleton width="90%" height="10px" rounded />
                    <Skeleton width="90%" height="10px" rounded />
                  </div>
                </>
                :
                <>
                  <div className="flex flex-row gap-4 justify-start items-center">
                    <div className="w-8 lg:w-12 h-8 lg:h-12 flex justify-center items-center rounded-full bg-sta-secondary text-white">
                      <FiCalendar className="w-4 lg:w-8 h-4 lg:h-8" aria-label="hidden" />
                    </div>
                    <p className="font-semibold text-sta-secondary text-sm lg:text-lg">
                      {dateFormat(item.createdAt, 'dddd, D MMMM YYYY')}
                    </p>
                  </div>
                  <p className="font-bold text-white text-base lg:text-2xl">
                    {item.title}
                  </p>
                  {
                    item.content &&
                    <div className="html-viewer text-white text-sm lg:text-lg hidden 2xl:block"
                      dangerouslySetInnerHTML={{ __html: item.content.substring(0, 150) }} />
                  }
                </>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

type CardEventProps = {
  children?: React.ReactNode
  loading?: boolean
  active?: boolean
  showYear?: boolean
  enableYear?: boolean
  item?: EventItem
  onClick?: () => void
  onThumbnailEnter?: () => void
  onThumbnailLeave?: () => void
}

function CardEvent(props: CardEventProps): React.JSX.Element {
  const {
    loading = false,
    active = false,
    showYear = false,
    enableYear = false,
    item,
    onClick = () => { },
    onThumbnailEnter = () => { },
    onThumbnailLeave = () => { },
  } = props

  const cursor = (loading ? "cursor-wait" : "cursor-pointer")

  return (
    <>
      {
        enableYear &&
        <div className="h-[2lh]">
          {
            showYear &&
            <p className="font-bold text-sta-primary text-base md:text-2xl">
              {dateFormat(item.startedAt, 'YYYY')}
            </p>
          }
        </div>
      }
      <div className={"relative w-full h-full " + cursor} onClick={onClick}>
        {
          !loading && item.categories && item.categories.length > 0 &&
          <Badge variant="secondary" appendClassNames="absolute top-[10px] left-[10px] z-[1]">
            <span className="uppercase">
              {item.categories[0].name}
            </span>
          </Badge>
        }
        <div className="w-full h-full min-h-[300px] md:min-h-[550px]">
          <div className={
            "max-w-[375px] max-h-[375px] ease-in-out rounded-3xl " +
            (!loading && active ? "bg-sta-primary opacity-50" : "bg-white")
          }
            onMouseEnter={onThumbnailEnter}
            onMouseLeave={onThumbnailLeave}>
            {
              loading ?
                <>
                  <Skeleton width="100%" height="100%" rounded />
                </>
                :
                <img className={
                  "aspect-square w-full h-full object-fit object-center rounded-3xl shadow-xl " +
                  (active ? "opacity-20" : "opacity-100")
                }
                  alt="thumbnail" src={item.thumbnailUrl || eventDefaultThumbnail} />
            }
          </div>
          <div className="w-full px-2 md:px-4 py-4 md:py-8">
            <div className="flex flex-row gap-4 justify-center items-start">
              <div className="basis-2/12 flex flex-col gap-2 justify-center items-center">
                {
                  loading ?
                    <>
                      <Skeleton width="100%" height="100%" rounded />
                      <Skeleton width="100%" height="100%" rounded />
                    </>
                    :
                    <>
                      {
                        item.startedAt &&
                        <>
                          <span className="text-sta-primary text-xs md:text-base">
                            {dateFormat(item.startedAt, 'MMM')}
                          </span>
                          <span className="font-bold text-sta-primary text-2xl md:text-4xl">
                            {dateFormat(item.startedAt, 'D')}
                          </span>
                        </>
                      }
                    </>
                }
              </div>
              <div className="basis-10/12 flex flex-col gap-2">
                {
                  loading ?
                    <>
                      <Skeleton width="70%" height="100%" rounded />
                      <Skeleton width="100%" height="100%" rounded />
                    </>
                    :
                    <>
                      {
                        item.startedAt && item.finishedAt &&
                        <span className="font-light text-black text-xs md:text-base">
                          {
                            isSameDay(item.startedAt, item.finishedAt) ?
                              <>
                                {`${dateFormat(item.startedAt, 'h:mm a')} - ${dateFormat(item.finishedAt, 'h:mm a')}`}
                              </>
                              :
                              <>
                                {`${dateFormat(item.startedAt, 'MMM D')} - ${dateFormat(item.finishedAt, 'MMM D')}`}
                              </>
                          }
                        </span>
                      }
                      <p className="font-bold text-black text-base md:text-2xl">
                        {item.title}
                      </p>
                    </>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

type ModalEventDetailProps = {
  children?: React.ReactNode
  item: EventItem
}

function ModalEventDetail(props: ModalEventDetailProps): React.JSX.Element {
  const {
    item,
  } = props

  return (
    <div className="h-full max-h-[500px] overflow-y-auto">
      <div className="grid grid-cols-12 gap-4 md:gap-8 xl:gap-[75px]">
        <div className="col-span-12 lg:col-span-4">
          <img alt="logo" className="aspect-square object-fit md:object-cover object-center"
            src={item.thumbnailUrl || eventDefaultThumbnail} />
        </div>
        <div className="col-span-12 lg:col-span-8">
          <div className="flex flex-col gap-6 md:gap-8 xl:gap-12 justify-center items-start">
            <div className="flex flex-col gap-5 xl:gap-6">
              <p className="font-bold text-xl md:text-4xl text-sta-primary">
                {item.title}
              </p>
              <div className="grid grid-cols-12 gap-4">
                {
                  item.startedAt &&
                  <>
                    <div className="col-span-4">
                      <p className="font-bold text-sta-primary text-base md:text-2xl">
                        Date
                      </p>
                    </div>
                    <div className="col-span-8">
                      <div className="text-base md:text-lg">
                        {
                          item.startedAt &&
                          <span>
                            {dateFormat(item.startedAt, 'DD MMMM YYYY')}
                          </span>
                        }
                        {
                          item.finishedAt && !isSameDay(item.startedAt, item.finishedAt) &&
                          <>
                            &nbsp;-&nbsp;
                            <span>
                              {dateFormat(item.finishedAt, 'DD MMMM YYYY')}
                            </span>
                          </>
                        }
                      </div>
                    </div>
                    <div className="col-span-4">
                      <p className="font-bold text-sta-primary text-base md:text-2xl">
                        Time
                      </p>
                    </div>
                    <div className="col-span-8">
                      <div className="text-base md:text-lg">
                        {
                          item.startedAt &&
                          <span>
                            {dateFormat(item.startedAt, 'h:mm a')}
                          </span>
                        }
                        {
                          item.finishedAt &&
                          <>
                            &nbsp;-&nbsp;
                            <span>
                              {dateFormat(item.finishedAt, 'h:mm a')}
                            </span>
                          </>
                        }
                      </div>
                    </div>
                  </>
                }
                {
                  item.venue &&
                  <>
                    <div className="col-span-4">
                      <p className="font-bold text-sta-primary text-base md:text-2xl">
                        Venue
                      </p>
                    </div>
                    <div className="col-span-8">
                      <div className="text-base md:text-lg">
                        {item.venue}
                      </div>
                    </div>
                  </>
                }
                {
                  item.fees &&
                  <>
                    <div className="col-span-4">
                      <p className="font-bold text-sta-primary text-base md:text-2xl">
                        Fees
                      </p>
                    </div>
                    <div className="col-span-8">
                      <div className="text-base md:text-lg">
                        {item.fees}
                      </div>
                    </div>
                  </>
                }
              </div>
            </div>
            {
              item.content &&
              <div className="html-viewer text-base md:text-xl" dangerouslySetInnerHTML={{ __html: item.content }} />
            }

            {
              item.registrationLink &&
              <div>
                <ButtonLink to={item.registrationLink} target="_blank" variant="primary">
                  <span className="uppercase">
                    Click to Register
                  </span>
                </ButtonLink>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}