import * as React from "react"
import { GoLinkExternal } from "react-icons/go"
import { ButtonLink } from "../components/Button"
import { Container } from "../components/Container"
import { Meta } from "../components/Meta"
import { Navigation } from "../components/Page"
import { NewsletterSection } from "../features/Newsletter"
import { useAutoPosition, useHashFragment, usePage } from "../hooks"

export default function Constitution(): React.JSX.Element {
  const [page] = usePage({
    slug: "constitution"
  })
  const [navItems] = React.useState([
    {
      to: "/about-us#profile",
      content: "What is STA",
      ref: React.useRef<HTMLDivElement>()
    },
    {
      to: "/about-us#activity",
      content: "Understand what we do",
      ref: React.useRef<HTMLDivElement>()
    },
    {
      to: "/about-us#milestone",
      content: "Our Story",
      ref: React.useRef<HTMLDivElement>()
    },
    {
      to: "/about-us#partnership",
      content: "Alliance & Partnership",
      ref: React.useRef<HTMLDivElement>()
    },
    {
      to: "/the-council",
      content: "Meet The Council",
    },
    {
      to: "/constitution",
      content: "Constitution",
    }
  ])

  useAutoPosition()
  useHashFragment()

  return (
    <>
      <Meta>
        {{
          title: `${page.detail.title || "Constitution"}`,
          description: `The Constitution serves as a legal framework that defines the purpose, objectives, and operational procedures of the organization. It establishes the rights and responsibilities of its members, the composition and powers of the governing body, and the processes for decision-making and dispute resolution.`,
        }}
      </Meta>

      <div className="w-full h-[250px] md:h-[400px] bg-sta-primary">
        <div className="w-full h-full flex justify-start items-center">
          <Container size="md">
            <p className="font-bold text-2xl md:text-5xl text-white my-10 xl:my-0">
              {page.detail.title || "Constitution"}
            </p>
          </Container>
        </div>
      </div>

      <Navigation items={navItems} loading={page.loading} sticky />

      {
        page.detail.fields.boolVal("CONSTITUTION_VISIBILITY") &&
        <>
          <div></div> {/* @note: do not remove to avoid newlseter section contain below element attributes */}
          <Container size="md">
            <div className="scroll-my-28 py-10 md:py-20">
              <div className="flex flex-col gap-4 md:gap-8">
                <div className="flex flex-col gap-4">
                  <p className="w-fit font-bold text-black text-xl md:text-4xl border-b-4 border-sta-primary">
                    {page.detail.fields.textVal("CONSTITUTION_HEADING_TEXT", `STA Constitution`)}
                  </p>
                  {
                    !page.detail.fields.empty("CONSTITUTION_SUBHEADING_TEXT") &&
                    <p className="font-bold text-sta-secondary text-base md:text-2xl">
                      {page.detail.fields.textVal("CONSTITUTION_SUBHEADING_TEXT")}
                    </p>
                  }
                </div>
                <div className="html-viewer flex flex-col gap-2 text-sm md:text-lg"
                  dangerouslySetInnerHTML={{
                    __html: page.detail.fields.textVal(
                      "CONSTITUTION_CONTENT_TEXT",
                      `
                      <p>
                        The Constitution serves as a legal framework that defines the purpose, objectives, and operational procedures of the organization. 
                        It establishes the rights and responsibilities of its members, the composition and powers of the governing body, and the processes for decision-making and dispute resolution. 
                        By adhering to its Constitution, an association ensures transparency, accountability, and effective governance, fostering trust and collaboration among its members and stakeholders.
                      </p>
                    `
                    )
                  }}>
                </div>
                <div className="flex justify-start items-center gap-4 my-2">
                  <ButtonLink variant="primary" appendClassNames="flex flex-row gap-2 justify-between items-center"
                    to={page.detail.fields.tryTextVal("CONSTITUTION_VIEW_CTA_TO", `/file/STA-Constitution-rev.2024v1.pdf`)}
                    target="_blank" hideCrawl>
                    <span className="uppercase">
                      {page.detail.fields.textVal("CONSTITUTION_VIEW_CTA_TEXT", `View Document`)}
                    </span>
                    <GoLinkExternal className="w-4 h-4" aria-hidden="true" />
                  </ButtonLink>
                </div>
              </div>
            </div>
          </Container>
        </>
      }

      <NewsletterSection />
    </>
  )
}