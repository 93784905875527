import * as React from "react"
import * as ReactDOM from "react-dom"
import { HiXMark } from "react-icons/hi2"
import {
  ModalSize,
  ModalSizeProps,
  ModalStyle
} from "./Resource"

type ModalProps = {
  children?: {
    header?: React.ReactNode
    content?: React.ReactNode
    footer?: React.ReactNode
  }
  opened?: boolean
  closeable?: boolean
  lostable?: boolean
  size?: ModalSizeProps
  portalId?: string
  onClose?: () => void
}

const styleBuilder = new ModalStyle()

export function Modal(props: ModalProps): React.JSX.Element {
  const {
    opened = false,
    closeable = false,
    lostable = false,
    size = ModalSize.MEDIUM,
    portalId = "",
    children = {},
  } = props

  const isReactSnap = navigator && navigator.userAgent === "ReactSnap"

  const handleClose = () => {
    props.onClose && props.onClose()
  }

  const handleLostFocus = () => {
    if (!lostable) {
      return
    }

    props.onClose && props.onClose()
  }

  React.useEffect(() => {
    if (opened) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "unset"
    }
  }, [opened])

  styleBuilder
    .setSize(size)
    .setOpened(opened)
    .setTheme("sta")

  const containerClassNames = styleBuilder.buildContainer()
  const elementClassNames = styleBuilder.buildElement()
  const backdropClassNames = styleBuilder.buildBackdrop()

  function createModal(): React.JSX.Element {
    return (
      <div className={containerClassNames}>
        <div className={backdropClassNames} onClick={handleLostFocus} />

        <div className={elementClassNames}>
          {
            ((children && children.header) || closeable) &&
            <div className="w-full flex flex-row gap-2 justify-between items-start">
              <div className="w-full">
                {children.header}
              </div>

              {
                closeable &&
                <button onClick={handleClose} className="text-black hover:text-gray-600">
                  <HiXMark aria-hidden="true" className="w-6 md:w-8 h-6 md:h-8" />
                </button>
              }
            </div>
          }

          {
            children && children.content &&
            <div className="w-full">
              {children.content}
            </div>
          }

          {
            children && children.footer &&
            <div className="w-full">
              {children.footer}
            </div>
          }
        </div>
      </div>
    )
  }

  if (isReactSnap) {
    return <></>
  }

  return (
    document.getElementById(portalId) ?
      ReactDOM.createPortal(createModal(), document.getElementById(portalId)!)
      :
      createModal()
  )
}