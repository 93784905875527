import * as React from "react"
import { FaChevronLeft, FaChevronRight } from "react-icons/fa6"
import {
  NavigationPosition,
  NavigationPositionProps,
  NavigationVariant,
  NavigationVariantProps,
} from "./Resource"

const themes = new Map<string, Map<string, string>>()

const midTheme = new Map<string, string>()
midTheme.set("container", "")
midTheme.set("button", "absolute z-[5] top-1/2 w-10 md:w-12 xl:w-14 3xl:w-16 h-10 md:h-12 xl:h-14 3xl:h-16 flex justify-center items-center rounded-full bg-[rgba(200,_200,_200,_0.7)] hover:bg-[rgba(200,_200,_200,_0.9)]")
midTheme.set("icon", "w-5 md:w-6 xl:w-8 h-5 md:h-6 xl:h-8")
midTheme.set(`variant-${NavigationVariant.PRIMARY}`, "text-white hover:text-sta-primary disabled:text-slate-200 disabled:cursor-not-allowed")

themes.set("mid", midTheme)

export class NavigationStyle {
  private variant: string = ""
  private position: string = ""

  public buildContainer(): string {
    let style = ""

    const theme = themes.get(this.position)
    if (!theme) {
      return style
    }

    if (theme.has("container")) {
      style += theme.get("container")
    }

    return style
  }

  public buildButton(): string {
    let style = ""

    const theme = themes.get(this.position)
    if (!theme) {
      return style
    }

    if (theme.has("button")) {
      style += theme.get("button")
    }

    if (theme.has(`variant-${this.variant}`)) {
      style += " " + theme.get(`variant-${this.variant}`)
    }

    return style
  }

  public buildIcon(): string {
    let style = ""

    const theme = themes.get(this.position)
    if (!theme) {
      return style
    }

    if (theme.has("icon")) {
      style += theme.get("icon")
    }

    return style
  }

  public setVariant(variant: string): NavigationStyle {
    this.variant = variant
    return this
  }

  public setPosition(position: string): NavigationStyle {
    this.position = position
    return this
  }

}

type CarouselNavigationProps = {
  enabled?: boolean
  position?: NavigationPositionProps
  variant?: NavigationVariantProps
  prevDisabled?: boolean
  nextDisabled?: boolean
  onPrev?: () => void
  onNext?: () => void
}

const navigationStyle = new NavigationStyle()

export function CarouselNavigation(props: CarouselNavigationProps): React.JSX.Element {
  const {
    enabled = false,
    position = NavigationPosition.MIDDLE,
    variant = NavigationVariant.PRIMARY,
    prevDisabled = false,
    nextDisabled = false,
  } = props

  navigationStyle
    .setPosition(position)
    .setVariant(variant)

  const containerClassnames = navigationStyle.buildContainer()
  const buttonClassnames = navigationStyle.buildButton()
  const iconClassnames = navigationStyle.buildIcon()

  return (
    <>
      {
        enabled && position === "mid" &&
        <div className={containerClassnames}>
          <button aria-label="previous" onClick={props.onPrev}
            className={"left-[10px] lg:left-[35px] " + buttonClassnames} disabled={prevDisabled}>
            <FaChevronLeft className={iconClassnames} aria-hidden="true" />
          </button>
          <button aria-label="next" onClick={props.onNext}
            className={"right-[10px] lg:right-[35px] " + buttonClassnames} disabled={nextDisabled}>
            <FaChevronRight className={iconClassnames} aria-hidden="true" />
          </button>
        </div>
      }
    </>
  )
}
