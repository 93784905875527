
export enum CarouselVariant {
  PRIMARY = "primary"
}

export type CarouselVariantProps = "primary"

export enum NavigationPosition {
  MIDDLE = "mid",
}

export type NavigationPositionProps = "mid"

export enum NavigationVariant {
  PRIMARY = "primary",
}

export type NavigationVariantProps = "primary"

export enum PaginationPosition {
  BOTTOM = "bottom"
}

export type PaginationPositionProps = "bottom"
