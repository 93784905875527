import * as React from "react"
import { Container } from "../components/Container"
import { Meta } from "../components/Meta"
import { Navigation } from "../components/Page"
import { NewsletterSection } from "../features/Newsletter"
import { useAutoPosition, useHashFragment, usePage } from "../hooks"

type MemberItem = {
  name: string
  designation: string
  organisation?: string
}

export default function Council(): React.JSX.Element {
  const [page] = usePage({
    slug: "the-council"
  })
  const [navItems] = React.useState([
    {
      to: "/about-us#profile",
      content: "What is STA",
    },
    {
      to: "/about-us#activity",
      content: "Understand what we do",
    },
    {
      to: "/about-us#milestone",
      content: "Our Story",
    },
    {
      to: "/about-us#partnership",
      content: "Alliance & Partnership",
    },
    {
      to: "/the-council",
      content: "Meet The Council",
    },
    {
      to: "/constitution",
      content: "Constitution",
    }
  ])
  const [member, setMember] = React.useState({
    items: []
  })

  React.useEffect(() => {
    const memberItems = page.detail.fields.collectionVal("MEMBER_ITEMS")

    setMember((prevState) => {
      return {
        ...prevState,
        loading: false,
        items: memberItems.map((member, i: number) => {
          return {
            name: member.textVal("name", ""),
            designation: member.textVal("designation", ""),
            organisation: member.textVal("organisation"),
          }
        }),
      }
    })
  }, [page])

  useAutoPosition()
  useHashFragment()

  return (
    <>
      <Meta>
        {{
          title: `${page.detail.title || "The Council"}`,
          description: `STA's dedicated council members, embodying diverse expertise, steer our industry-forward initiatives.Their visionary leadership propels innovation and strengthens stakeholder partnerships, driving lasting impact in Singapore's transport landscape.`,
        }}
      </Meta>

      <div className="w-full h-[250px] md:h-[400px] bg-sta-primary">
        <div className="w-full h-full flex justify-start items-center">
          <Container size="md">
            <p className="font-bold text-2xl md:text-5xl text-white my-10 xl:my-0">
              {page.detail.title || "The Council"}
            </p>
          </Container>
        </div>
      </div>

      <Navigation items={navItems} loading={page.loading} sticky />

      {
        page.detail.fields.boolVal("MEMBER_VISIBILITY") &&
        <>
          <div></div> {/* @note: do not remove to avoid newlseter section contain below element attributes */}
          <Container size="md">
            <div className="scroll-my-28 py-10 md:py-20">
              <div className="flex flex-col gap-4 md:gap-8">
                <div className="flex flex-col gap-4">
                  <p className="w-fit font-bold text-black text-xl md:text-4xl border-b-4 border-sta-primary">
                    {page.detail.fields.textVal("MEMBER_HEADING_TEXT", `Council Members`)}
                  </p>
                  {
                    !page.detail.fields.empty("MEMBER_SUBHEADING_TEXT") &&
                    <p className="font-bold text-sta-secondary text-base md:text-2xl">
                      {page.detail.fields.textVal("MEMBER_SUBHEADING_TEXT")}
                    </p>
                  }
                </div>
                <div className="html-viewer flex flex-col gap-2 text-sm md:text-lg"
                  dangerouslySetInnerHTML={{
                    __html: page.detail.fields.textVal(
                      "MEMBER_CONTENT_TEXT",
                      `
                      <p>
                        STA's dedicated council members, embodying diverse expertise, steer our industry-forward initiatives.
                      </p>
                      <p>
                        Their visionary leadership propels innovation and strengthens stakeholder partnerships, driving lasting impact in Singapore's transport landscape.
                      </p>
                    `
                    )
                  }}>
                </div>
                <hr className="border-2 border-sta-secondary my-4" />
                <div className="grid grid-cols-12 gap-6 sm:gap-12 auto-rows-fr">
                  {
                    member.items.map((member, i: number) => {
                      return (
                        <React.Fragment key={`member-item-${i}`}>
                          <div className="col-span-12 sm:col-span-6 md:col-span-4 xl:col-span-3">
                            <CardCouncil item={member} />
                          </div>
                        </React.Fragment>
                      )
                    })
                  }
                </div>
              </div>
            </div>
          </Container>
        </>
      }

      <NewsletterSection />
    </>
  )
}

type CardCouncilProps = {
  children?: React.ReactNode
  item?: MemberItem
}

function CardCouncil(props: CardCouncilProps): React.JSX.Element {
  const {
    item,
  } = props

  return (
    <div className="flex flex-col gap-2">
      <span className="font-semibold text-sta-primary text-sm md:text-lg xs:h-full h-[2lh]">
        {item.designation}
      </span>
      <p className="font-bold text-sta-secondary text-base md:text-2xl uppercase">
        {item.name}
      </p>
      {
        item.organisation &&
        <p className="font-light text-black text-xs md:text-base">
          {item.organisation}
        </p>
      }
    </div>
  )
}