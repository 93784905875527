
export enum ModalSize {
  FULL = "full",
  XXLARGE = "2xl",
  EXTRA = "xl",
  LARGE = "lg",
  MEDIUM = "md",
  SMALL = "sm",
  TINY = "xs",
}

export type ModalSizeProps = "full" | "2xl" | "xl" | "lg" | "md" | "sm" | "xs"

const themes = new Map<string, Map<string, string>>()

const defaultTheme = new Map<string, string>()
defaultTheme.set("container", "w-full h-full justify-center items-center fixed inset-0 z-40")
defaultTheme.set("container:opened-true", "flex")
defaultTheme.set("container:opened-false", "hidden")
defaultTheme.set("element", "p-4 border bg-white z-10")
defaultTheme.set("backdrop", "fixed inset-0 bg-black opacity-60")
defaultTheme.set(`size-${ModalSize.FULL}`, "w-full h-full shadow-lg")
defaultTheme.set(`size-${ModalSize.XXLARGE}`, "w-11/12 xl:w-full max-w-full xl:max-w-7xl shadow-lg rounded-lg")
defaultTheme.set(`size-${ModalSize.EXTRA}`, "w-11/12 lg:w-full max-w-full lg:max-w-5xl shadow-lg rounded-lg")
defaultTheme.set(`size-${ModalSize.LARGE}`, "w-11/12 md:w-full max-w-full md:max-w-3xl shadow-lg rounded-lg")
defaultTheme.set(`size-${ModalSize.MEDIUM}`, "w-11/12 md:w-full max-w-full md:max-w-xl shadow-md rounded-md")
defaultTheme.set(`size-${ModalSize.SMALL}`, "w-11/12 md:w-full max-w-full md:max-w-md shadow-md rounded-md")
defaultTheme.set(`size-${ModalSize.TINY}`, "w-11/12 md:w-full max-w-full md:max-w-xs shadow-md rounded-md")

const staTheme = new Map<string, string>()
staTheme.set("container", "w-full h-full justify-center items-center fixed inset-0 z-40")
staTheme.set("container:opened-true", "flex")
staTheme.set("container:opened-false", "hidden")
staTheme.set("element", "inline-flex flex-col gap-6 xl:gap-8 items-start p-5 md:p-8 lg:p-10 xl:p-12 border-[3px] md:border-[10px] border-sta-secondary bg-white z-10")
staTheme.set("backdrop", "fixed inset-0 bg-black opacity-60")
staTheme.set(`size-${ModalSize.FULL}`, "w-full h-full shadow-lg")
staTheme.set(`size-${ModalSize.XXLARGE}`, "w-11/12 xl:w-full max-w-full xl:max-w-7xl shadow-lg rounded-[12px] md:rounded-[35px]")
staTheme.set(`size-${ModalSize.EXTRA}`, "w-11/12 lg:w-full max-w-full lg:max-w-5xl shadow-lg rounded-[12px] md:rounded-[35px]")
staTheme.set(`size-${ModalSize.LARGE}`, "w-11/12 md:w-full max-w-full md:max-w-3xl shadow-lg rounded-[12px] md:rounded-[35px]")
staTheme.set(`size-${ModalSize.MEDIUM}`, "w-11/12 md:w-full max-w-full md:max-w-xl shadow-md rounded-[12px] md:rounded-[35px]")
staTheme.set(`size-${ModalSize.SMALL}`, "w-11/12 md:w-full max-w-full md:max-w-md shadow-md rounded-[12px] md:rounded-[35px]")
staTheme.set(`size-${ModalSize.TINY}`, "w-11/12 md:w-full max-w-full md:max-w-xs shadow-md rounded-[12px] md:rounded-[35px]")

themes.set("", defaultTheme)
themes.set("sta", staTheme)

export class ModalStyle {
  private theme: string = ""
  private size: string = ""
  private opened: boolean = false

  public buildContainer(): string {
    let style = ""

    const theme = themes.get(this.theme)
    if (!theme) {
      return style
    }

    if (theme.has("container")) {
      style += theme.get("container")
    }

    if (theme.has(`container:opened-${this.opened}`)) {
      style += " " + theme.get(`container:opened-${this.opened}`)
    }

    return style
  }

  public buildElement(): string {
    let style = ""
    const theme = themes.get(this.theme)
    if (!theme) {
      return style
    }

    if (theme.has("element")) {
      style += theme.get("element")
    }

    if (theme.has(`size-${this.size}`)) {
      style += " " + theme.get(`size-${this.size}`)
    }

    return style
  }

  public buildBackdrop(): string {
    let style = ""

    const theme = themes.get(this.theme)
    if (!theme) {
      return style
    }

    if (theme.has("backdrop")) {
      style += theme.get("backdrop")
    }

    return style
  }

  public setTheme(theme: string): ModalStyle {
    this.theme = theme
    return this
  }

  public setSize(size: string): ModalStyle {
    this.size = size
    return this
  }

  public setOpened(opened?: boolean): ModalStyle {
    this.opened = opened || false
    return this
  }
}